import { isMobile } from '../utils/index'
export default {
  address: '台中市西屯區台灣大道三段251號旁(大遠百旁)',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3640.2070367546457!2d120.64114233087344!3d24.164470828821717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7cf8636b6b3002c4!2zMjTCsDA5JzUyLjEiTiAxMjDCsDM4JzQ0LjMiRQ!5e0!3m2!1szh-TW!2stw!4v1661839157807!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/xQGLTC4Y7SHusnhw6',
  phone: '04-2255-3338',
  fbLink: 'https://www.facebook.com/100082849226852/',
  fbMessage: 'https://m.me/100082849226852/',
  caseName: 'VVS1',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '潤隆建設',],
    ['建築設計', '李明哲建築師事務所',],
    ['公設設計', 'Pia Interior',],
    ['外觀景觀', 'MAG玫格設計',],
    ['銷售企劃', '巨豐旅館管理顧問',],
    ['建照號碼', '103中都建字第02657號',],
  ],

  gtmCode: ['TJ344HX'], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    // subTitle: '若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！
    room_type: ['兩房','三房'],//房型
  },
  lixin_logo:true, //false

}